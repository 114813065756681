<template>
  <section :class="extraClass">
    <b-card no-body v-loading="loading">
      <b-card-header>
        <b-card-title class="d-flex w-100">
          <div class="flex-grow-1">
            <slot name="title" />
          </div>
          <div class="text-right">
            <slot name="button" />
            <slot name="refresh_button">
              <b-button variant="secondary" :size="tableSize" @click="loadList()" class="mr-1 btn-icon">
                <feather-icon icon="RefreshCwIcon" />
              </b-button>
            </slot>
            <slot name="reset_button">
              <b-button variant="secondary" :size="tableSize" class="btn-icon" @click="resetFilter()">
                <feather-icon icon="DeleteIcon" />
              </b-button>
            </slot>
            <slot name="setting_button">
              <b-button variant="secondary" :size="tableSize" class="btn-icon" id="column_setting" v-if="hideable_column.length > 0">
                <feather-icon icon="PauseIcon" />
              </b-button>
            </slot>
            <b-popover target="column_setting" triggers="focus" placement="leftbottom" :delay="0" v-if="hideable_column.length > 0">
              <div style="overflow-y: auto" :style="{ 'max-height': height + 'px' }" class="m-0 p-0">
                <table class="table table-hover table-sm">
                  <tbody>
                  <tr v-for="(v, i) in hideable_column" :key="i">
                    <td style="padding: 5px; border: none">
                      <b-form-checkbox v-model="show_column" :value="v.key">{{ v.label }}</b-form-checkbox>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <b-button block variant="light" @click="resetColumnFilter">Reset</b-button>
              </div>
            </b-popover>
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div :style="{ height: height + 'px' }">
          <b-table ref="advanceTable" v-if="rows != null" hover responsive :sticky-header="height - 5 + 'px'" :items="rows" :fields="columns_after_hidden_check" primary-key="id" show-empty empty-text="No matching records found" @row-clicked="openModalForEdit" no-local-sorting :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :selectable="selectable" @row-selected="onRowSelected">
            <template #head()="data">
              <!-- Sort -->
              <div class="mt-1 w-100 text-center text-nowrap" style="padding-bottom: 2px;">
                {{ data.label }}
                <template v-if="data.field.sortable">
                  <feather-icon icon="ChevronUpIcon" :class="{ 'text-primary': !sortDesc && sortBy === data.field.key, 'text-secondary': sortDesc || sortBy !== data.field.key }" />
                  <feather-icon icon="ChevronDownIcon" :class="{ 'text-primary': sortDesc && sortBy === data.field.key, 'text-secondary': !sortDesc || sortBy !== data.field.key }" />
                </template>
              </div>

              <!-- Search -->
              <b-form-input v-if="data.field.filtertype === 'input'" :size="tableSize" v-model="filter[data.field.modelName]" @keydown.enter.native="search()" autocomplete="off" :disabled="data.field.disabled" />
              <b-form-select v-if="data.field.filtertype === 'select'" :size="tableSize" :options="data.field.options" v-model="filter[data.field.modelName]" @change="search()" :disabled="data.field.disabled" />
              <flat-pickr v-if="data.field.filtertype === 'date'" class="form-control" v-model="filter[data.field.modelName]" :class="['form-control-' + tableSize, data.field.disabled ? 'bg-disabled' : 'bg-white']" @on-change="search()" :disabled="data.field.disabled" :config="{ enableTime: true,enableSeconds:true }" />
              <flat-pickr v-if="data.field.filtertype === 'datetime'" class="form-control" v-model="filter[data.field.key]" :class="['form-control-' + tableSize, data.field.disabled ? 'bg-disabled' : 'bg-white']" @on-change="search()" :disabled="data.field.disabled" :config="{ mode: 'range' }" />
              <flat-pickr v-if="data.field.filtertype === 'month'" class="form-control" v-model="filter[data.field.key]" :class="['form-control-' + tableSize, data.field.disabled ? 'bg-disabled' : 'bg-white']" @on-change="search()" :disabled="data.field.disabled" :config="monthConfig" />
              <flat-pickr v-if="data.field.filtertype === 'fiscal'" class="form-control" v-model="filter[data.field.key]" :class="['form-control-' + tableSize, data.field.disabled ? 'bg-disabled' : 'bg-white']" @on-change="search()" :disabled="data.field.disabled" :config="fiscalConfig" />
            </template>

            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope" /></template>
          </b-table>
        </div>
        <!-- show pages -->
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mr-1">
            <span class="text-nowrap ">
              Showing {{ ((page - 1) * size) + 1 }} to
            </span>
            <b-form-select
              v-model="size"
              :options="pageOptions"
              class="mx-1"
              @change="handlePageSizeChange"
            />
            <span class="text-nowrap"> of {{ totalSize }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="page"
              :total-rows="totalSize"
              :per-page="size"
              first-number
              last-number
              class="mb-0 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="handlePageChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BPagination, BFormSelect, BButton, BTooltip, VBToggle,
  BCard, BCardBody, BTable, BRow, BCol, BFormInput, BFormGroup, BSidebar,
  BInputGroupPrepend,BCardHeader, BCardTitle,
  BInputGroup,BPopover,
  BBreadcrumb,
  BBreadcrumbItem, BBadge, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import flatPickrMonthSelect  from "flatpickr/dist/plugins/monthSelect"
import "flatpickr/dist/plugins/monthSelect/style.css"
import "@core/scss/vue/libs/vue-flatpicker.scss";
import { fetcher, FetchMethod } from "@/libs/axios";
import { endOfMonthDate, startOfMonthDate } from "@/views/erp/utils/time";
import { isObject } from "lodash";
import { isDevelopmentSite } from "@/views/erp/utils/system";
export default {
  components: {
    ToastificationContent,
    BPopover,
    BPagination,
    BFormSelect,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
    flatPickr,
    BFormGroup,
    BCardHeader, BCardTitle,
    BSidebar,
    vSelect,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BSpinner,
  },
  props: {
    url: {
      type: String
    },
    baseFilters: {
      type: Object
    },
    columns: {
      default: () => [],
      type: Array
    },
    height: {
      default: 355,
      type: Number
    },
    defaultPageSize: {
      default: 10,
      type: Number
    },
    id:{
      type:Number
    },
    tableSize: {
      type: String,
      default: 'md',
    },
    extraClass: {
      type: String,
      default: '',
    },
    selectable: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    columns_after_hidden_check() {
      return this.columns
        .filter((e) => !e.adminOnly || this.is_admin && e.adminOnly)
        .filter((e) => e.hideable == null || e.hideable == false || this.show_column.includes(e.key))
        .map((e) => {
          let ret = JSON.parse(JSON.stringify(e));
          ret["label"] = this.$t(e.label);
          ret["thStyle"] = { padding: "0px 3px 5px 3px" };
          if (e.width) {
            ret["thStyle"]["width"] = e.width + "px";
            ret["thStyle"]["max-width"] = e.width + "px";
            ret["thStyle"]["min-width"] = e.width + "px";
          }
          if (e.minWidth) {
            ret["thStyle"]["min-width"] = e.minWidth + "px";
          }
          if (e.maxWidth) {
            ret["thStyle"]["max-width"] = e.maxWidth + "px";
          }
          if (e.sortable) ret["thStyle"]["backgroundImage"] = "none !important";
          ret["thClass"] = ["bg-white", "p-1", "p-" + this.tableSize];
          ret["tdClass"] = ["text-nowrap"];
          ret["tdClass"] = ["text-" + (e.align ? e.align : "left"), "p-1", "p-" + this.tableSize];
          ret["options"] = e.options ? [{ value: "", text: "" }].concat(Object.keys(e.options).map((k) => ({ value: k, text: e.options[k] }))) : [{ value: "", text: "" }];
          return ret;
        });
    },
    hideable_column() {
      return this.columns.filter((e) => !e.adminOnly || this.is_admin && e.adminOnly).filter((e) => e.hideable);
    },
    empty_filter() {
      let hash = {};
      this.columns.forEach((e) => {
        hash[e.key] = "";
      });
      return hash;
    }
  },
  data() {
    return {
      loading: false,
      rows: null,
      pageOptions: [10, 25, 50, 100, 200],
      page: 1, // 当前页码
      size: 10, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      condition: {
        page: 1,
        size: 10,
      },
      filter: {},
      current_filter: {},
      sortBy: "",
      sortDesc: true,
      show_column: [],
      monthConfig: {
        plugins: [
          new flatPickrMonthSelect({
            shorthand: true,
            dateFormat: "Y-m",
            altFormat: "Y-m"
          })
        ]
      },
      fiscalConfig: {
        plugins: [
          new flatPickrMonthSelect({
            shorthand: true,
            dateFormat: "Y/0m",
            altFormat: "Y/0m"
          })
        ]
      },
      is_admin: false,
    };
  },
  mounted() {
    this.is_admin = localStorage.getItem("isAdmin") === "Y";
    this.filter = JSON.parse(JSON.stringify(this.empty_filter));
    this.current_filter = JSON.parse(JSON.stringify(this.empty_filter));
    if (localStorage.getItem("show_column_" + this.url) == null) {
      this.resetColumnFilter();
    } else {
      this.show_column = JSON.parse(localStorage.getItem("show_column_" + this.url));
    }
    this.loadList();
  },
  methods: {
    handlePageChange(page) {
      this.condition.page = page
      this.loadList()
    },
    handlePageSizeChange(active) {
      this.condition.page = 1
      this.condition.size = active
      this.loadList()
    },
    async loadList(additionalParams) {
      let label;
      let value;
      if (additionalParams) {
        for (let a in additionalParams) {
          label = a
          value = additionalParams[a]
        }
      }
      this.loading = true;
      let url = this.url;
      url += "?pageNumber=" + (this.condition.page - 1);
      url += "&pageSize=" + this.condition.size;

      const sortByField = this.columns.filter((e) => e.key === this.sortBy);
      if (sortByField.length === 1) {
        url += "&sortDirection=" + (this.sortDesc ? "desc" : "asc");
        url += "&sortBy=" + sortByField[0].modelName
      }

      // Pro-process of filter values
      this.columns
        .forEach((e) => {
          if (e.filtertype === "input") {
            if (this.filter[e.key]) this.filter[e.key] = this.filter[e.key].trim();
          } else if (e.filtertype === "datetime") {
            if (this.filter[e.key]) {
              const d = this.filter[e.key].split(" to ");
              if (d.length !== 2) {
                d.push(d[0]);
              }
              this.filter[e.key + "From"] = d[0] + "T00:00:00.000Z";
              this.filter[e.key + "To"] = d[1] + "T23:59:59.000Z";
            }
          } else if (e.filtertype === "month") {
            if (this.filter[e.key]) {
              this.filter[e.key + "From"] = startOfMonthDate(this.filter[e.key]) + "T00:00:00.000Z";
              this.filter[e.key + "To"] = endOfMonthDate(this.filter[e.key]) + "T23:59:59.000Z";
            }
          }
      });

      if (isObject(this.baseFilters)) {
        Object.keys(this.baseFilters).forEach(key => {
          this.filter[key] = this.baseFilters[key];
        });
      }

      this.current_filter = JSON.parse(JSON.stringify(this.filter));

      if (additionalParams) {
        if (this.current_filter.hasOwnProperty(label)) {
          for(let b in this.current_filter){
            if (b == label) {
              this.current_filter[b] = value
            }
          }
        }else{
          this.$set(this.current_filter,label,value)
        }
      }

      let paramStr = ""
      let params = this.current_filter
      for (const k in params){
        paramStr += k + "=" + params[k] + "&"
      }

      let finalUrl = url + "&" + paramStr;

      try {
        const accountRes = await fetcher(
          finalUrl,
          FetchMethod.GET
        );
        if (accountRes.data) {
          this.rows = accountRes.data
        }else{
          this.rows = accountRes
        }

        if (accountRes.page) {
          this.page = accountRes.page.page_number + 1
          this.size = accountRes.page.page_size
          this.totalSize = accountRes.page.total_records
          this.totalPage = accountRes.page.total_pages
        }

      } catch (e) {
        console.info(e)
      } finally {
        this.loading = false;
      }
      // this.$http.get(url,this.current_filter).then((res) => {
      //   const info = res.data.data;
      //   this.rows = info.content
      //   this.page = info.page
      //   this.size = info.size
      //   this.totalSize = info.totalSize
      //   this.totalPage = info.totalPage
      //   this.loading = false;
      // });
    },
    indexOfColumnKey(key) {
      return this.columns.findIndex(e => {
        return e.key === key;
      })
    },
    search() {
      this.page = 1;
      this.condition.page = 1;

      // Workaround some bug in datepicker
      this.$nextTick(() => {
        if (JSON.stringify(this.filter) !== JSON.stringify(this.current_filter)) {
          this.loadList();
        }
      })
    },
    resetFilter() {
      this.filter = JSON.parse(JSON.stringify(this.empty_filter));
      this.$emit("clear");
      this.search();
    },
    openModalForEdit(item, index, event) {
      if (event.altKey && isDevelopmentSite()) {
        let win = window.open('');
        win.document.write('<pre>' + JSON.stringify(item, null, 2) + '</pre>');
        win.document.close();
        return;
      }

      this.$emit("edit", item);
    },
    onRowSelected(item) {
      this.$emit("row-selected", item);
    },
    resetColumnFilter() {
      this.show_column = this.columns.filter((e) => !e.defaultHide).map((e) => e.key);
    }
  },
  watch: {
    sortBy(n, o) {
      this.loadList();
    },
    sortDesc(n, o) {
      this.loadList();
    },
    show_column(n, o) {
      localStorage.setItem("show_column_" + this.url, JSON.stringify(n));
    }
  }
};
</script>
<style scoped>
.bg-disabled{
  background-color:#efefef !important
}

.embbed .card {
  box-shadow: none;
  margin-bottom: 0;
}

.embbed .card-header {
  padding: 0.5rem;
}

.embbed .card-body {
  padding: 0;
}
</style>

